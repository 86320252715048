import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Helmet from 'react-helmet';
import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import { widths } from '../styles/variables';
import noPictureImg from '../../assets/noticias/no-image.png';
import Header from '../components/Header';
import LayoutMain from '../components/LayoutMain';
import seloImage from '../../assets/logo.png';

const Ender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.background};
  padding: 16px;
  color: #fff;
`;

const Breaker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
  background: url(${props => props.image});
  background-size: 100% auto;
  div {
    width: 100%;
    max-width: ${widths.xl}px;
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    h3 {
      color: #fff;
      font-size: 21px;
    }
  }
  h1 {
    color: #fff;
    font-size: 24px;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  min-height: 50vh;
  background-color: ${props => props.background};
  div {
    width: 100%;
    max-width: ${widths.xl}px;
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
    ${({ sidebyside }) =>
    sidebyside &&
    `
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${widths.md}px) {
      flex-direction: column;
    }
    `}
  }
  article {
    flex: 1;
    min-height: 30vh;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 32px;
    h1 {
      flex: 1;
    }
    p {
      flex: 1;
    }
  }
  h1 {
    color: ${props => (props.fontcolor ? props.fontcolor : '#000')};
    font-size: 24px;
  }
  p {
    color: ${props => (props.fontcolor ? props.fontcolor : '#000')};
    font-size: 16px;
  }
`;

const Noticia = styled(Section)`
  .container {
    max-width: ${widths.xl - 200}px;
  }
  .noticiaCover {
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;

    // background: url(${props => props.coverPicture ? props.coverPicture : noPictureImg});
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;

    img {
      width: 100%;
      height: auto;
    }
  }
  .content {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 16px;
    height: auto;
    text-align: justify;
    text-indent: 50px;
    font-size: 18px;
  }
  .author {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 16px;
    height: auto;
    text-align: right;
  }
`;

const Footer = styled(Section)`
  min-height: auto;
  padding: 0px;
  div {
    max-width: 100%;
    padding: 0px;
  }
  article {
    align-items: left;
    padding: 8px;
  }
  .img-footer {
    align-items: center;
  }
  img {
    width: 100px;
    height: auto;
  }
  a {
    color: #fff;
  }
`;

const Content = styled(Container)`
  background: #fff;
  width: 100% !important;
`;

const Orange = styled.span`
  color: orange;
`;

const IndexPage = ({ pageContext: { noticia, sobreSite } }) => {
  const title = noticia.Titulo + " - Loja Sinergia";
  const logoUrl = sobreSite.Logo ? (sobreSite.Logo.url ? sobreSite.Logo.url : null) : null;
  const carrosselUrl = (sobreSite.Carrossel && sobreSite.Carrossel.length > 0) ? (sobreSite.Carrossel[0].url ? sobreSite.Carrossel[0].url : null) : null;

  const caminhoImagem = (noticia.CapaFoto) ? (noticia.CapaFoto.url ? noticia.CapaFoto.url : null) : null;
  let urlImg = "https://lojasinergia.machadoti.com.br" + caminhoImagem;
  if (!caminhoImagem) urlImg = noPictureImg;

  return (
    <IndexLayout>
      <Helmet title={title} />
      <LayoutMain>
        <Header logoImage={logoUrl} backgroundImg={sobreSite.Carrossel} />
        <Page>
          <Content>

            <Noticia coverPicture={urlImg}>
              <div className="container">
                <h3><Link to="/noticias">Voltar</Link></h3>
                <h1>{noticia.Titulo}</h1>
                <div className="noticiaCover">
                  <img src={urlImg} />
                </div>
                <div className="content">
                  <ReactMarkdown source={noticia.Descricao} />
                </div>
                <div className="author">
                  <p>
                    {new Date(noticia.Data).toLocaleDateString('pt-BR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}
                  </p>
                </div>
              </div>
            </Noticia>

            <Footer background="#1f3447" fontcolor="#ffffff" sidebyside>
              <div>
                <article className="img-footer">
                  <img src={seloImage} />
                </article>
                <article>
                  <h1><Orange>→</Orange> E-mail</h1>
                  <p>
                    <a href="mailto:124sec@glojars.org.br">124sec@glojars.org.br</a>
                  </p>
                </article>
              </div>
            </Footer>
          </Content>
        </Page>
      </LayoutMain >
    </IndexLayout >
  );
};

export default IndexPage;
